.screening-steps-dropdown {
  display: none;
}

.no-margin * {
  margin: 0;
} /* Is used to undo the automatic margin of <p> tags */

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .screening-steps-dropdown {
    display: block;
    float: right;
    margin: 5px;
  }

  .screening-steps {
    display: none;
  }
}

.content-table .ant-table-content {
  overflow: visible !important;
}

.content-table .rdw-link-modal {
  height: auto;
}

.no-margin * {
  margin: 0;
} /* Is used to undo the automatic margin of <p> tags */

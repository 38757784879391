:root {
  --main-blue: #001529;
  --light-grey: rgba(0, 0, 0, 0.45);
  --background: #f5f5f5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-picker-dropdown {
  @media (max-width: 768px) {
    inset: 51.9333px auto auto 0px !important;
  }
}
.ant-picker-dropdown .ant-picker-panel-container {
  @media (max-width: 768px) {
    max-width: 100vw !important;
    overflow: auto !important;
    inset: 51.9333px auto auto 0px !important;
  }
}

.credit-card .most-chosen {
  text-align: center;
  vertical-align: text-top;
  align-self: center;
  font-size: 16px;
  color: whitesmoke;
  background-color: var(--main-blue);
  border-radius: 16px 16px 0 0;
  height: 28px;
  width: 275px;
}
